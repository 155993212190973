import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Deployment from '../contracts/Game.json';
import { getWeb3 } from '../util';
import { web3Call } from './contract';

const initialState = {
  loading: false,
  account: null,
  error: '',
  network: {},
};

const networks = {
  1: {
    name: "networks",
    blockExplorer:"https://etherscan.io/tx/"
  },
  3: {
    name: "ropsten",
    blockExplorer:"https://etherscan.io/tx/"
  },
  4: {
    name: "rinkeby",
    blockExplorer:"https://rinkeby.etherscan.io/tx/"
  },
  42: {
    name: "kovan",
    blockExplorer:"https://etherscan.io/tx/"
  },
  137: {
    name: "matic",
    blockExplorer:"https://polygonscan.com/tx/"
  },
  31337:  {
    name: "hardhat",
    blockExplorer:"https://etherscan.io/tx/"
  },
  1337: {
    name: "ganacha",
    blockExplorer:"https://etherscan.io/tx/"
  },
  80001: {
    name: "mumbai",
    blockExplorer:"https://mumbai.polygonscan.com/tx/"
  }
};

// If we keep these as "function () {}" syntax they preserve scope for "this" keyword when using "bind"
const handleNetworkChange = function (network) {
  this.dispatch(updateNetwork({ network: parseInt(network) }));
}

// If we keep these as "function () {}" syntax they preserve scope for "this" keyword when using "bind"
const handleAccountChange = function ([account]) {
  this.dispatch(updateAccount({ account: account }));

  // to use after reveal
  this.dispatch(web3Call({
    method: 'getTokensByPlayer',
    stateProp: 'tokens',
    payload: account
  }));

  /* TODO: Joe
  // to use before reveal
  this.dispatch(web3Call({
    method: 'getTokenIdsByPlayerBeforeReveal',
    stateProp: 'tokens',
    payload: account
  }));
  */

  this.dispatch(web3Call({
    method: 'name',
    stateProp: 'name'
  }));

  this.dispatch(web3Call({
    method: 'currentId',
    stateProp: 'totalMinted'
  }));

  this.dispatch(web3Call({
    method: 'isRevealed',
    stateProp: 'isRevealed'
  }));

  this.dispatch(web3Call({
    method: 'grandPrizeTokenId',
    stateProp: 'grandPrizeTokenId'
  }));

  this.dispatch(web3Call({
    method: 'getRewardTokensPerPlayer',
    stateProp: 'tokensReward',
    payload: account
  }));
}

export const connect = createAsyncThunk(
  'blockchain/connect',
  async function (arg, { dispatch }) {
    if (window.ethereum) {
      const web3 = await getWeb3();

      try {
        const network = await web3.eth.getChainId();

        // Here we bind dispatch from thunk to be used in handleNetworkChange's function scope
        window.ethereum.on('chainChanged', handleNetworkChange.bind({ dispatch }));

        if (Deployment.chainId.toString() === network.toString()) {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const [account] = await window.ethereum.request({ method: 'eth_accounts' });

          window.ethereum.on('accountsChanged', handleAccountChange.bind({ dispatch }));
          return { account, network };
        } else {
          throw new Error(`This application works with ${networks[Deployment.chainId].name} :)`);
        }
      } catch (error) {

        const { code } = error;
        
        if (code === -32002) {
          throw new Error('Permissions request already pending');
        } else if (code === 4001) {
          throw new Error('User rejected the request');
        }

        throw error;
      }
    } else {
      throw new Error('Metamask is required :)');
    }
  }
);

export const disconnect = createAsyncThunk(
  'blockchain/disconnect',
  async function () {
    if (window.ethereum) {
      const web3 = getWeb3();
      await web3.givenProvider.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {}
          }
        ]
      });

      window.ethereum.removeListener('accountsChanged', handleAccountChange);
      window.ethereum.removeListener('chainChanged', handleNetworkChange);

      blockchainClear();
    }
  }
);

export const blockchainSlice = createSlice({
  name: 'blockchain',
  initialState,
  reducers: {
    blockchainClear: () => ({ ...initialState }),
    blockchainErrorSet: (state, action) => {
      const { error } = action.payload;
      state.error = error;
    },
    blockchainErrorClear: (state) => {
      state.error = '';
    },
    updateAccount: (state, action) => {
      const { account } = action.payload;
      state.account = account;
    },
    updateNetwork: (state, action) => {
      const { network } = action.payload;
      state.network = networks[network];
    }
  },
  extraReducers: builder => {
    builder.addCase(connect.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(connect.fulfilled, (state, action) => {
      const { account, network } = action.payload;
      state.account = account;
      state.network = networks[network];
      state.loading = false;
    });

    builder.addCase(connect.rejected, (state, action) => {
      const { message } = action.error;
      state.error = message;
      state.loading = false;
    });
  }
});

export const { blockchainClear, blockchainErrorClear, updateAccount, updateNetwork } = blockchainSlice.actions;

export default blockchainSlice.reducer;

import React from "react";
import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { InView } from 'react-intersection-observer';
import "../styles/footer.scss";

function Footer() {
    return (
        <footer className="footer">
            <Container>
                <InView triggerOnce={true}>
                    {({ inView, ref, entry }) => (
                        <div ref={ref} className={`animate ${inView ? 'animate--down' : ''}`}>
                           <h1 className="footer__title">CONNECT WITH US</h1>
                        </div>
                    )}
                </InView>
                
                
                <div className="d-flex align-items-center justify-content-center footer__links">
                    <InView triggerOnce={true}>
                        {({ inView, ref, entry }) => (
                            <div ref={ref} className={`animate ${inView ? 'animate--up' : ''}`} style={{animationDelay: "0.15s"}}>
                                <a className="footer__link" target="_blank" href="https://discord.gg/wacdonalds">
                                    <FontAwesomeIcon icon={faDiscord} />
                                </a>
                            </div>
                        )}
                    </InView>
                    <InView triggerOnce={true}>
                        {({ inView, ref, entry }) => (
                            <div ref={ref} className={`animate ms-4 ${inView ? 'animate--up' : ''}`} style={{animationDelay: "0.30s"}}>
                                <a className="footer__link" target="_blank" href="https://twitter.com/wacdonalds">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            </div>
                        )}
                    </InView>
                    
                    
                    {/* <a className="footer__link" href="https://wacdonalds.io">
                        <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a className="footer__link" href="https://wacdonalds.io">
                        <FontAwesomeIcon icon={faMedium} />
                    </a> */}
                </div>
            </Container>
            <Container>
  
                <div className="d-flex align-items-center justify-content-center mb-0 mt-4">
                    <InView triggerOnce={true}>
                        {({ inView, ref, entry }) => (
                            <div ref={ref} className={`animate ${inView ? 'animate--up' : ''}`} style={{animationDelay: "0.15s"}}>
                                <a className="text-white text-decoration-none powered d-block" target="_blank" href="https://www.quicknode.com/">
                                    <span className="me-3 powered__text">Powered By Quicknode</span>
                                    <svg className="powered__logo" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0466 25.5867C10.5396 25.5867 6.08315 21.2056 6.08315 15.7918C6.08315 10.3779 10.5396 5.99688 16.0466 5.99688C23.282 5.99688 28.0096 13.2931 25.2137 19.6731L18.8425 17.6242L27.8402 26.4696C30.4835 23.6544 32.0933 19.9063 32.0933 15.7751C32.0933 7.06299 24.9087 0 16.0466 0C7.18456 0 0 7.06299 0 15.7751C0 24.4872 7.18456 31.5502 16.0466 31.5502C19.1475 31.5502 22.0281 30.684 24.4851 29.2015L31.6188 32L22.6212 23.138C20.8589 24.6538 18.5545 25.5867 16.0466 25.5867Z"/>
                                    </svg>

                                </a>
                            </div>
                        )}
                    </InView>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;

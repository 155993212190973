import Web3 from 'web3';
import Deployment from './contracts/Game.json';

export const getQNWeb3 = () => new Web3("https://crimson-solitary-dream.rinkeby.quiknode.pro/de46e1918c1a7a4709c5117d5c2ded6342c28153/");
// export const getQNWeb3 = () => (window.ethereum && new Web3(window.ethereum));
export const getWeb3 = () => (window.ethereum && new Web3(window.ethereum));

export const readContract = () => {
  const { abi, address } = Deployment.contracts['Game'];
  const web3 = getQNWeb3();
  return web3 && new web3.eth.Contract(abi, address);
};

export const writeContract = () => {
  const { abi, address } = Deployment.contracts['Game'];
  const web3 = getWeb3();
  return web3 && new web3.eth.Contract(abi, address);
};

export const getContractAddress = () => {
  const { address } =  Deployment.contracts['Game'];
  return address;
};
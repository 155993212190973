import React from "react";
import "../styles/about.scss";
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { InView } from 'react-intersection-observer';
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faFileAlt } from '@fortawesome/free-solid-svg-icons'

function About() {

    return (
        <section className="about">

            <Container>

                
                <Row>
                    <Col xs={12} md={7} lg={8} xl={9}>
                        <InView triggerOnce={true}>
                            {({ inView, ref, entry }) => (
                                <div ref={ref} className={`animate section__title ${inView ? 'animate--right' : ''}`}>
                                    <h1 className="section__title">ABOUT CRYPTOPOLY</h1>
                                </div>
                            )}
                        </InView>
                        <InView triggerOnce={true}>
                            {({ inView, ref, entry }) => (
                                <div ref={ref} className={`animate ${inView ? 'animate--up' : ''}`} style={{animationDelay: "0.25s"}}>
                                    <p className="about__text">Welcome to WacDonald's, home of the one and only Cryptopoly game in the metaverse! How can we take your order? Each item on our menu comes with one ERC-721 token that represents an asset tile on the board. If you get a Cryptopoly, come back to us and redeem instantly for your prize!</p>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey={0}>
                                            <Accordion.Header>
                                                <div className="mb-0 ps-0 d-flex w-100 align-items-center">
                                                    <FontAwesomeIcon  className="accordion__icon me-2 mt-0" icon={faChevronDown} /> 
                                                    Find Out More
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="faq-answer ps-0">
                                                <div className="ps-0">
                                                    <p className="about__text">WacDonald's Cryptopoly is an on-chain NFT collecting art experience. Each group of assets represents some important component of crypto culture. At WacDonald's, we wanted to celebrate the ridiculousness that is NFT culture with an equally ridiculous way to demonstrate the contractual powers of NFTs in a familiar and fun manner. WacDonald's Cryptopoly will offer 8788 tiles available for mint. There are 10 cryptopolies that can be achieved: Good Boi Park, Shitcoin Road, Privacy Street, DEX Avenue, Stabilized Utilities, NFT Gardens, Crosschain Lane, The Grand Central Exchange Station, Bluechip Boulevard, and the Bitcoin Block. For the first wallet that can collect all of the cryptopolies redeem reward NFTs, and one Claimed Airdrop reward NFT will receive the WacDonald's Cryptopoly GigaChad Award!</p>
                                                    <a className="btn btn-warning mt-3" target="_blank"href="/pdfs/white-paper-cryptopoly.pdf" > 
                                                        <FontAwesomeIcon  className="me-2" icon={faFileAlt} /> 
                                                        Read White Paper
                                                    </a>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            )}
                        </InView>
                       
                    </Col>
                    <Col xs={12} md={5} lg={4} xl={3}>
                        <InView triggerOnce={true}>
                            {({ inView, ref, entry }) => (
                                <div ref={ref} className={`animate ${inView ? 'animate--left' : ''} text-center`} style={{animationDelay: "0.55s"}}>
                                    <img className="w-75 w-md-100 mt-4 mt-md-0" src="/assets/imgs/mr-cryptopoly-color.png" alt="About Cryptopoly"/>
                                </div>
                            )}
                        </InView>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default About;

import React, {useMemo } from "react";


import "../styles/ticker.scss";

function Ticker() {
    return (
        <div className="ticker-wrapper">
            <div className="ticker">
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/BigWac.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/BLK CHAIN.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/coin-heads.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/DAO Treasury.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Digg.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/ETH Bag.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Fries.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/ETH Bill.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Russian Doll.svg" alt="Big Wac" />
                </div>

                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/BigWac.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/BLK CHAIN.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/coin-heads.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/DAO Treasury.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Digg.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/ETH Bag.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Fries.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/ETH Bill.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Russian Doll.svg" alt="Big Wac" />
                </div>


                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/BigWac.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/BLK CHAIN.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/coin-heads.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/DAO Treasury.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Digg.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/ETH Bag.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Fries.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/ETH Bill.svg" alt="Big Wac" />
                </div>
                <div className="img-wrapper">
                    <img src="assets/svgs/tickers/Russian Doll.svg" alt="Big Wac" />
                </div>

            </div>
        </div>

    );
}

export default Ticker;

import { configureStore } from '@reduxjs/toolkit';
import contractReducer from './contract';
import blockchainReducer from './blockchain';

const store = configureStore({
  reducer: {
    contract: contractReducer,
    blockchain: blockchainReducer,
  }
});

export default store;

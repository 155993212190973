import React from "react";

import "./App.scss";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import HeaderComingSoon from "./components/HeaderComingSoon";

import About from "./components/About";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import Ticker from "./components/Ticker";
import LandingComingSoon from "./components/LandingComingSoon";
import { ParallaxProvider } from 'react-scroll-parallax';
import {isMobile} from 'react-device-detect';

export const StyledButton = styled.button`
  padding: 8px;
`;

function App() {
  return (
    <ParallaxProvider>
      <s.Screen>
        <HeaderComingSoon />
        <LandingComingSoon />
        <About />
        <Ticker />
        <Faq />
        { isMobile &&
          <img height="1757" width="4100" className="w-100 h-auto position-relative d-portrait" src="/assets/imgs/hero.jpg" alt="WacDonald's Cryptopoly Exchange Counter"/>
        }
        <Footer />
      </s.Screen>
    </ParallaxProvider>
  );
}

export default App;

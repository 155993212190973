import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { readContract, writeContract  } from '../util';

import PropertySet from '../propertySet.json';
import RewardSet from '../rewardSet.json';

const PropertyGroups = PropertySet.properties.reduce((memo, { categoryId }, index) => {
    if (!memo[categoryId]) memo[categoryId] = [];
    memo[categoryId].push(index);
    return memo;
}, {});


const RewardGroups = RewardSet.rewards.reduce((memo, { categoryId }, index) => {
  if (!memo[categoryId]) memo[categoryId] = [];
  memo[categoryId].push(index);
  return memo;
}, {});

const initialState = {
  loading: false,
  name: '',
  tokenCount: 0,
  tokens: [],
  properties: {},
  rewards: {},
  error: '',
  success: '',
  totalMinted: 0,
  loadingDefaults: null,
  isRevealed: false,
  grandPrizeTokenId: 0,
  tokensReward: [],
  receipt: '',
  grandPrizeTokenOwner: "",
  contractState: 0,
  totalNumberMintedByPurchase: 0
};

export const web3Call = createAsyncThunk(
  'contract/web3Call',
  async function ({ stateProp, method, payload, from }) {
    // console.log(methods);
    // TODO: we don't have named parameters in web3 functions. So I don't understand necessety of the 2 lines below (IMHO).
    const args = payload ? [payload] : [];
    const args2 = from ? [from] : [];
    // we will never have call() with parameters. It is implemented internally in Metamask as "pre-call" for send().
    // moreover send() does never return. So the line return { [stateProp]: tx.transactionHash }; is excessive in fact.
    if (args2.length) {
      const { methods: writeMethod } = await writeContract();
      const tx = await writeMethod[method](...args).send(...args2);
      console.log({ [stateProp]: tx.transactionHash });
      return { [stateProp]: tx.transactionHash };
    } else {
      const { methods: readMethod } = await readContract();
      const result = await readMethod[method](...args).call(...args2);
      console.log({ [stateProp]: result });
      return { [stateProp]: result };
    }
  }
);

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    contractClear: () => ({ ...initialState }),
    contractErrorSet: (state, action) => {
      const { error } = action.payload;
      state.error = error;
    },
    contractErrorClear: (state) => {
      state.error = '';
    },
    contractSuccessSet: (state, action) => {
      const { success } = action.payload;
      state.success = success;
    },
    contractSuccessClear: (state) => {
      state.success = '';
    }
  },
  extraReducers: builder => {
    builder.addCase(web3Call.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(web3Call.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach(k => {
        state[k] = action.payload[k];

        if (k === 'tokens') {


          const mapped = action.payload[k].reduce((memo, [tokenId, propertyType]) => { //[tokenId, propertyType, uri]
            const group = Object.keys(PropertyGroups).filter(pg => PropertyGroups[pg].indexOf(parseInt(propertyType, 10)) > -1)[0];

            if (!memo[group]) {
              memo[group] = {};
            };
            if (!memo[group][propertyType]) {
              memo[group][propertyType] = [];
            }

            memo[group][propertyType].push(tokenId);

            return memo;
          }, {});

          state.properties = mapped;
        }
        
        if (k === 'tokensReward') {
          const mapped = action.payload[k].reduce((memo, [tokenId, propertyType]) => { //[tokenId, propertyType, uri]
            const group = Object.keys(RewardGroups).filter(pg => RewardGroups[pg].indexOf(parseInt(propertyType, 10)) > -1)[0];

            if (!memo[group]) {
              memo[group] = {};
            };
            if (!memo[group][propertyType]) {
              memo[group][propertyType] = [];
            }
            memo[group][propertyType].push(tokenId);

            return memo;
          }, {});
          state.rewards = mapped;
        }
      });
      state.loading = false;
    });

    builder.addCase(web3Call.rejected, (state, action) => {
      const { message } = action.error;
      state.error = message;
      state.loading = false;
    });
  }
});

export const { contractClear, contractErrorSet, contractErrorClear, contractSuccessSet, contractSuccessClear } = contractSlice.actions;

export default contractSlice.reducer;

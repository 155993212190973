import React from "react";

import * as s from "../styles/globalStyles";
import "../styles/landing.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { scroller } from "react-scroll";
import Typewriter from "./Typewriter";
import {InView} from "react-intersection-observer";
import Bg from './../svgs/backgroundBanner3.svg'
import { ParallaxBanner } from 'react-scroll-parallax';
import {isMobile} from 'react-device-detect';


function LandingComingSoon() {
   
    return (
        <section className="landing pb-0">
            <div id="scene" className="position-relative d-landscape">
                <InView triggerOnce={true}>
                    {({ inView, ref, entry }) => (
                        <div ref={ref} className={`animate typewriter-animation  ${inView ? 'animate--right' : ''}`}>
                            <Typewriter inView={inView}  />
                        </div>

                    )}
                </InView>
                <img height="1757" width="4100" className="w-100 h-auto hero-img" src="/assets/imgs/hero.jpg" alt="WacDonald's Cryptopoly Exchange Counter"/>
            </div> 
            <s.Container className="h-100 py-5" flex={1} ai={"center"} jc={"center"}>
            { isMobile ? (
                <div className="bg-img --mobile">
                  <img src={Bg} alt="Wacdonald's Cryptopoly Backdrop" />
                </div>
            ):(
                <div className="bg-img">
                    <ParallaxBanner
                        layers={[{ image: Bg, speed: -45 }]}
                        className="aspect-[2/1] h-100"
                    />
                </div>
            )}

                <h1 className="title-text --title mb-2"><span className="--gold">w</span>acdonalds</h1>
                <h1 className="title-text --subtitle --red --secondary-font">CRYPTOPOLY</h1>
                <img className="landing__img d-portrait" height="250" style={{width: "auto"}} src="/assets/imgs/Mr.Cryptopoly_face.png" alt="Mr. Cryptopoly"/>
                <h1 className="tag-line --secondary-font">COMING SOON</h1>
                <s.SpacerSmall />
                <a className="landing__arrow-down animate--bounce d-portrait"
                    onClick={() => scroller.scrollTo('about', {
                        smooth: true,
                        offset: -66,
                        duration: 300,
                    })}>
                        <FontAwesomeIcon icon={faArrowDown} />
                </a>
            </s.Container>   
        </section>
    );
}

export default LandingComingSoon;
